import React from 'react';
import { Media } from 'react-bootstrap';
import MarvinNavbar from './MarvinNavbar_hook.jsx';

export default function Privacy() {
  return (
    <React.Fragment>
     
      <div className='mt-5'></div>
       <Media>
        <img
          width={64}
          height={64}
          className="mr-3"
          src="/la-logo-brand-circle.png"
          alt="Autoscribe"
        />
        <Media.Body>
          <h2>AutoScribe Privacy Policy</h2>
          <h4>As at 25th April 2021</h4>
          <p>
            AutoScribe is currently in closed beta and it's use is by invitation only. We currently do not track customer's behaviour in any way, shape or form.
            The app records your voice via your phone's microphone, only when you use the app and choose to record. When your recording is finished, we upload it to a 
            Google Storage Bucket (a cloud based folder) and it is then transcibed by our voice recognition models. 
          </p>
          <p>
            You have full control of your data and it can be deleted at any time. All data is stored on EU based servers is not transferred outside of the EU.
          </p>
          <p>
            You have the right to access, rectify, remove your data etc, as per the GDPR. Please go to www.dataprotection.ie for more details as to your rights
          </p>
          <p>
            We are a startup in closed Beta. We will update this policy to be more detailed as soon as possible. But we really don't care what you do, so we won't be tracking you.
            For more details, please contact hi@autoscribe.ie
          </p>
        </Media.Body>
      </Media>
      
    </React.Fragment>
  );
}
