//08/07/2021 - Updated for HTTPS
//27/09/2021 - Updated for Sharing - see code marked 19/08/21 for changes
/*****THIS IS THE ONE TO UPDATE*/

/* Attempted to get .env variables but no joy.  also setup another .env file in /ui/src in case that was issue.
webpack / dotenv libraries are not loading for some reason. One hardcoded IP address at top instead..

from https://medium.com/@trekinbami/using-environment-variables-in-react-6b0a99d83cf5

//const webpack = require('webpack');
//const dotenv = require('dotenv');


// call dotenv and it will return an Object with a parsed key 
 const env = dotenv.config().parsed;
  
  // reduce it to a nice object, the same as before
  const envKeys = Object.keys(env).reduce((prev, next) => {
    prev['process.env.${next}'] = JSON.stringify(env[next]);
    return prev;
  }, {});

  */


/* Note: Change the IP address in the ip_port variable*/
import React, { useContext }  from 'react';
// import URLSearchParams from 'url-search-params';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom'; //needed to move to Editor
import { AuthContext } from "./auth.js";
import MarvinContext from './MarvinContext.js';
import AudioFileTable from './AudioFileTable.jsx';
//import { auth, firebase } from "firebase";
//import firebase from "firebase/app";
import myFBtoken2 from "./fbtoken.js"
var mathUtils = require('../utils-module').Math;

//const ip_port = process.env.MARVIN_IP + ':' + process.env.API_SERVER_PORT;
//const ip_port = '34.77.190.188' + ':' + '3000';
const ip_port = 'www.autoscribe.eu' + ':' + '3000';

export default class GcsAudioFileList extends React.Component {
  constructor() {
    super();
    this.state = { audioFiles: [], isTranscribed: false, speakers: 1, transcribedFileId: null, toEditor: false, newTo: {}, audioLanguage: 'en-IE' };
    this.deleteAudioFile = this.deleteAudioFile.bind(this);
    this.transcribeAudioFile = this.transcribeAudioFile.bind(this);
    this.handleSpeakerChange = this.handleSpeakerChange.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.editTranscribedFile = this.editTranscribedFile.bind(this);
    //this.state = { transcribedFiles: [], toEditor: false, newTo: {} }; //from TranscribedFileList.jsx
  }

  // static mcontext = MarvinContext;
  //static mcontext = useContext(MarvinContext);

  componentDidMount() {
    //var toke = this.myFBtoken();
    this.loadData();
    //console.log("THe returned Token is:" + JSON.stringify(toke));
    //console.log("THe returned Token is:" + toke);
    // this.helloWorld();
    // let uploadedStatus = this.context.uploaded;
    // console.log('CDM uploadedStatus: ' + uploadedStatus);
  }

  componentDidUpdate(prevProps) {
    const { isTranscribed } = this.state;
    if(isTranscribed){
      // console.log(' G[CDU] context: 1 transcribed is ' + this.context.transcribed);
      // console.log(' G[CDU] isTranscribed is true');
      this.context.setTranscribedListStatus(true);
      
      // console.log(' G[CDU] context: 2 transcribed is ' + this.context.transcribed);
      this.setState({ isTranscribed: false });
    }

    // this.loadData();
    let uploadedStatus = this.context.uploaded;
    if(uploadedStatus == true){
      // console.log(' G[CDU] uploadedStatus is: ' + uploadedStatus);
      // Reset uploadStatus.
      this.context.setUploadStatus(false);
      this.loadData();
    }

    /*Using this to get the edit button enabled. Based on cosnole though, there appears to be a lot of re-rendering and it is not just becaus eof my additional code.. -- BL 15/11/2020*/
  let transcribedStatus = this.context.transcribed;
   console.log("GCS: transcribedStatus is:" + transcribedStatus);
    if(transcribedStatus == true){
      console.log("GCS: transcribedStatus is true - setting refresh:" );
      this.context.setTranscribedListStatus(false);
      this.loadData();
    }

    // console.log('CDU uploadedStatus: ' + uploadedStatus);
  }

  


  handleSpeakerChange(e) {
    console.log('handleSpeakerChange --->');
    console.log(e.target.value);
    console.log(e.target.getAttribute("mongo-id")); // need to change this 
    this.setState({ speakers: parseInt(e.target.value),  transcribedFileId: parseInt(e.target.getAttribute("mongo-id")) });
  }

  handleLanguageChange(e) {
    console.log('handleLanguageChange --->');
    console.log(e.target.value);
    console.log(e.target.getAttribute("mongo-id")); // need to change this 
    this.setState({ speakers: parseInt(e.target.value),  transcribedFileId: parseInt(e.target.getAttribute("mongo-id")) });
  }

  async helloWorld() {
    console.log('helloWorld --->');
      try {
       // const response = await fetch('http://34.77.190.188:3000/hello');
        const response = await fetch('https://' + ip_port + '/hello'); //HTTPS Change 08/07/2021 - BL
        
        if (response.ok) {
          // console.log(response.json());
          const json = await response.json();
          console.log(json);
        } else {
          throw new Error("Oops! Something went wrong");
        }
        // const json = await response.json();
        // console.log(json);

        // return json;
      } catch (error) {
        console.log(error);
      }
  }

 /*async myFBtoken()
 {
  var mytoken = firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
    // Send token to your backend via HTTPS
    // ...
    if (idToken) { 
      console.log("#1 inside myFBtoken: Have the Autoscribe token!" + idToken);
      return idToken;
    }
  }).catch(function(error) {
    // Handle error
    console.log("I do not have the autoscribe token :(");
  });
  return mytoken;
 }*/

  //async loadData(FBtoken) {
    async loadData() {
    console.log(' loadAudoFileData --->');
    
    //const response = await fetch('http://34.77.190.188:3000/list');
    //const response = await fetch('http://' + ip_port + '/listFB');
    //const FBtoken = await this.myFBtoken();
    const FBtoken = await myFBtoken2();
    console.log("#2. Inside LoadData. Token is:" + FBtoken);
    
    //HTTPS Change 08/07/2021 - BL
    const response = await fetch('https://' + ip_port + '/listFB', {
      headers: {FBToken: FBtoken}
      //body: {FBToken: FBtoken}
    })

    if (response.ok) {
      const json = await response.json();
      // Format name of file - remove UUID for displaying
      let displayJson = [...json];
      // let displayJson = Array.from(json);
console.log(' #files: ' + displayJson.length);
      json.forEach((aFile, idx) => {
        //console.log("OriginalName: " + aFile.originalname);
        //displayJson[idx].displayName = aFile.filename//.substring(aFile.filename.indexOf("_") + 1);
        displayJson[idx].displayName = aFile.originalname//.substring(aFile.filename.indexOf("_") + 1);
        //displayJson[idx].metadata.size = mathUtils.formatBytes(aFile.metadata.size);
        displayJson[idx].size = mathUtils.formatBytes(aFile.size);
        // displayJson[idx].metadata.codec = aFile.metadata.codec;
        // displayJson[idx].metadata.sampleRate = aFile.metadata.sampleRate;
      });
      this.setState({ audioFiles: displayJson });
    } else {
      throw new Error("GET /listFB error");
    }
  }

  async deleteAudioFile(index, displayName,convertedAudio,codec) {
    console.log(' deleteAudioFile ---> ' + index); //index is filename
    console.log(' deleteAudioFile b ---> ' + displayName);
    // Reset progress
    this.context.setProgress = -1;

    //get the Firebase token
    const FBtoken = await myFBtoken2();
    console.log("#2. Inside Delete Data. Token is:" + FBtoken);

    //HTTPS Change 08/07/2021 - BL
    //const response = await fetch('http://34.77.190.188:3000/delete', {
    const response = await fetch('https://' + ip_port + '/deleteFB', {
      method: 'DELETE',
      headers: {'content-type': 'application/json', FBToken: FBtoken},
      body: JSON.stringify({fileName: index, fileDisplayName: displayName, convertedAudioFilename: convertedAudio, codec: codec})
    })

    if (response.ok) {
      const json = await response.json();
      console.log(' delete response:');
      console.log(json);
      this.loadData();
    } else {
      throw new Error("DELETE /delete error");
    }
  }

  async transcribeAudioFile(index, codec, sampleRate, numberOfChannels, dataKey,lang,key) {
    console.log(' transcribeAudioFile ---> ' + index + ' ' + codec + ' ' + sampleRate + ' ' + numberOfChannels + ' data-key=' + dataKey + 'language=' + lang);
    // Reset progress
    this.context.setProgress = -1;

    // Default to 1 speaker which is the default value of the speaker in each <select>.
    let numSpeakers = 1;
    if(dataKey == this.state.transcribedFileId){
      numSpeakers = this.state.speakers;
      console.log('dataKey = transcribedFileId, speakers: ' + numSpeakers);
    }
    
    //get the Firebase token
    const FBtoken = await myFBtoken2();
    console.log("#2. Inside Transcribe Data. Token is:" + FBtoken);
  
    //HTTPS Change 08/07/2021 - BL
    //const response = await fetch('https://34.77.190.188:3000/transcribe', {
    const response = await fetch('https://' + ip_port + '/transcribe', {
      method: 'POST',
      headers: {'content-type': 'application/json', FBToken: FBtoken},
      body: JSON.stringify({fileName: index, codec: codec, sampleRate: sampleRate, numberOfChannels: numberOfChannels, numSpeakers: numSpeakers, audioLanguage: lang, key:key})
    })

    if (response.ok) {
      const json = await response.json();
      console.log('File transcribed');

      const delay = ms => new Promise(res => setTimeout(res, ms));

        const hangtight = async () => {
            await delay(1200);
            console.log("Waited 1s for Firebase");
            
            this.setState({ isTranscribed: true });    
          };
        hangtight();

      //this.loadData();
      //this.context.setTranscribedListStatus = true;
      
    } else {
      throw new Error("POST /transcribe error");
    }
  }

  //Added by Brian Leahy 28/09/2020 - taken from TranscribedFileList.jsx
  //19/08/21 Added "name" (UUID +filename) as a param to get the convertedfile name (for sharing functionality)
  async editTranscribedFile(tFileName, tfMongoId, aFileName,originalname,name) {
    // Reset progress
    this.context.setProgress = -1;
    console.log("hey! IS IT ME?");
    console.log("My params are: " + tfMongoId);
    console.log("My params are: " + name);
    // 1 - get transcript file from passed transcribedFile.name
    // 2 - get associated audio file URL
    // 3 - pass both to BbcEditor (Redirect to BbcEditor with the data)

    //HTTPS Change 08/07/2021 - BL
    //const fileResponse = await fetch('https://34.77.190.188:3000/getFileContents', {
    const fileResponse = await fetch('https://' + ip_port + '/getFileContents', {
      method: 'POST',
      headers: {'content-type': 'application/json'},
      body: JSON.stringify({transcriptFileName: tFileName })
    })

    let txt = "";
    if (fileResponse.ok) {
      txt = await fileResponse.text();
      txt = JSON.parse(txt);
    } else {
      throw new Error("POST /getFileContents error");
    }

    const thePath = "/bbc/" + tfMongoId;
//HTTPS Change 08/07/2021 - BL

    //const response = await fetch('https://34.77.190.188:3000/getTempMediaUrl', {
      const response = await fetch('https://' + ip_port + '/getTempMediaUrl', {
      method: 'POST',
      headers: {'content-type': 'application/json'},
       //body: JSON.stringify({audioFileName: aFileName })
       body: JSON.stringify({audioFileName: name }) // 19/08/21 Changed this for sharing
    })

    if (response.ok) {
      const json = await response.json();
      console.log(' getTempMediaUrl response:');
      console.log(json);

      // Define a location object
      const theTo = {
        pathname: thePath,
        state: {
          id: tfMongoId,
          fileName: tFileName,
          fileContents: txt,
          mediaUrl: json.audioUrl,
          orginalname: originalname
        }
      };
      //console.log("The To:" + JSON.stringify(theTo));
      this.setState({ toEditor: true, newTo: theTo });
      // This will force a re-render which will redirect to the BBC editor.
    } else {
      throw new Error("POST /getTempMediaUrl error");
    }
  }

  render() {
    const { audioFiles } = this.state;
    const { match } = this.props;

    if (this.state.toEditor === true) {
      console.log("Mynew is:" + this.state.newTo);
      return <Redirect to={this.state.newTo} />
    }

    // let uploadedStatus = this.context.uploaded;
    // if(this.context.uploaded == true){
    //   this.context.setUploadStatus = false;
    //   this.loadData();
    // } else {
    //   console.log('uploadStatus is: ' + this.context.uploaded);
    // }
    return (
      <AuthContext.Consumer>{
        Acontext => (
      <MarvinContext.Consumer>{
        mcontext => (
            <React.Fragment>
              <div className='mt-5'></div>
                  <AudioFileTable { ...this.state} audioFiles={audioFiles} transcribeAudioFile={this.transcribeAudioFile} deleteAudioFile={this.deleteAudioFile} handleSpeakerChange={this.handleSpeakerChange} editTranscribedFile={this.editTranscribedFile}/>
              <hr />
            </React.Fragment>
          )
        }
      </MarvinContext.Consumer>
        )}
       </AuthContext.Consumer>
    );
  }
}
GcsAudioFileList.contextType = MarvinContext;
