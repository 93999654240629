import React from 'react';
import MarvinContext from './MarvinContext';

export default class MarvinProvider extends React.Component {
    state = {
        uploaded: false,
        transcribed: false,
        progressPercentage: -1,
        setProgress: progress => this.setProgress(progress),
        setUploadStatus: uploadStatus => this.setUploadStatus(uploadStatus),
        setTranscribedListStatus: transcribedListStatus => this.setTranscribedListStatus(transcribedListStatus)
    };

    setProgress = progress => {
      console.log(' ---> setProgress to: ' + progress);
      this.setState({ progressPercentage: progress });
    }

    setUploadStatus = uploadStatus => {
      console.log(' ---> setUploadStatus to: ' + uploadStatus);
      this.setState({ uploaded: uploadStatus });
    }

    setTranscribedListStatus = transcribedListStatus => {
      console.log(' ---> setTranscribedListStatus to: ' + transcribedListStatus);
      this.setState({ transcribed: transcribedListStatus });
    }

    render() {
        return (
            <MarvinContext.Provider
                value= { this.state }
            >
                {this.props.children}
            </MarvinContext.Provider>
        );
    }
}
