//08/07/2021 - Updated for HTTPS

import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
// import { MarvinContext } from './MarvinProvider.jsx';
// const audioFile = this.context;
import MarvinContext from './MarvinContext.js';
import UserContext from './UserContext.js';
import myFBtoken2 from "./fbtoken.js"
//import {Redirect} from "react-router";
var strUtils = require('../utils-module').String;
const mm = require('music-metadata-browser');

var FormData = require('form-data');
//const ip_port = '34.77.190.188' + ':' + '3000'; //hardcoded until I can figure out how to get .env into jsx files
const ip_port = 'www.autoscribe.eu' + ':' + '3000';

var mytest,arse;
const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '15px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  /*backgroundColor: '#fafafa',*/
  backgroundColor: '#f5f5f5',
  color: '#bdbdbd',
  color: '#000022', /* text colour*/
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

// var xhr = new XMLHttpRequest();
function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => ++value); // update the state to force render
}

// export default class Contents extends React.Component {
const FileUpload = (props) =>
{
  // Use the useContext hook to access the Consumer in the Context
  const context = useContext(MarvinContext);
  const contextUser = useContext(UserContext);
    console.log('User signedIn: ' + contextUser.signedIn);

  const onDrop = useCallback(async acceptedFiles => {
    console.log(acceptedFiles);
    console.log('uploaded onDrop: ' + context.uploaded);
    var formData = new FormData();

    // BEGIN - progress calculation
    // xhr.open('post', 'http://' ip_port + '/upload', true);
    // xhr.upload.onprogress = function(egcobj) {
    //   if (e.lengthComputable) {
    //     var percentage = (e.loaded / e.total) * 100;
    //     console.log(percentage + "%");
    //   }
    // };
    // xhr.onerror = function(e) {
    //   console.log('Error');
    //   console.log(e);
    // };
    // xhr.onload = function() {
    //   console.log(this.statusText);
    // };
    // xhr.send(formData);
    // END - progress calculation


    // var promises = [obj1, obj2].map(function(obj){
    //   return db.query('obj1.id').then(function(results){
    //      obj1.rows = results
    //      return obj1
    //   })
    // })
    // Promise.all(promises).then(function(results) {
    //     console.log(results)
    // })

    const thePromises = acceptedFiles.map(async (file, index) => {
      return mm.parseBlob(file, {native: true, duration: true}) //{} added by BL 15/10/2020
        .then( metadata => {
          console.log("Inside FileUpload.jsx. Duration is: " + metadata.format.duration);
          console.log("Inside FileUpload.jsx. Filename is: " + JSON.stringify(file));
          formData.append(`file${index}`, file);
          formData.append(`file${index}_data`, JSON.stringify({codec: metadata.format.codec, sampleRate: metadata.format.sampleRate, numberOfChannels: metadata.format.numberOfChannels, Duration: metadata.format.duration}));
        })
        .catch( err => {
          console.error(err.message);
        });
    });
    // console.log(' User: ');
    // console.log(props.user);

    await Promise.all(thePromises);

    const FBtoken = await myFBtoken2();
    //console.log("#2. Inside FileUpLoad. Token is:" + FBtoken);
    
    //fetch('http://localhost:3000/upload', {
    fetch('https://' + ip_port + '/uploadFB', { //HTTPS change - 08/07/2021 
      // content-type header should not be specified!
      method: 'POST',
      headers: {FBToken: FBtoken},
      body: formData,
    })
      .then(response => response.json())
      .then(success => {

        //I want to wait for the file to be converted before refreshing (getCOnvertFile used now to overcome this. keeeping delay for the refresh!)
        //This also resolves the file box staying rendered even after upload!!
        const delay = ms => new Promise(res => setTimeout(res, ms));

        const hangtight = async () => {
            await delay(100);
            console.log("Waited a bit");
            context.setUploadStatus(true);
          
          };
        hangtight();

        // Trigger a list refresh
        console.log(' Got SUCCESS for upload');
        //context.setUploadStatus(true);
        //arse = context.uploaded;
        //arse = useForceUpdate();
        console.log(' <--- upload');
       // window.location.reload(); --not a great way to update....
        acceptedFiles.length = 0
        acceptedFiles.splice(0, acceptedFiles.length)
        
        //inputRef.current.value = ''

        //acceptedFiles.length = 0;
        //const [, updateState] = React.useState();
        //const forceUpdate = React.useCallback(() => updateState({}), []);
        //var a  = forceUpdate();
     // this.forceUpdate();
        //return <Redirect to="/files" />;
        
      })
      .catch(error => console.log(error));
  }, []);

 /* if (context.uploaded)
  {
    console.log("Uploaded is : " +context.uploaded )
    //const [, updateState] = React.useState();
      // const forceUpdate = React.useCallback(() => updateState({}), []);
      mytest = "file uploaded";
  }
  else{
    console.log("Uploaded is : " +context.uploaded );
    mytest = "file not uploaded";
  }*/

  

  function bum()
  {
    if(arse)
    {
      return( <h6>Transcript Ready in:</h6>) 
    }
    else{
      return(<h6>titty</h6>)
    }
  }

  
    

  /*if (arse) {
  const [mytest2, setmytest2] = React.useState(0);
    console.log("Inside arse")
  React.useEffect(() => {
    
    const upd = setmytest2(1);

      //if (context.uploaded) {
      
        mytest = "file uploaded";
        console.log("Inside use effect and true: " + mytest2)
 
    //return () => mytest;
  }, [mytest2]);
}*/
  // Use the react-dropzone hook: useDropzone
  const { isDragActive, getRootProps, getInputProps,
          acceptedFiles, rejectedFiles } = useDropzone({
              onDrop,
              accept: 'audio/*',
              multiple: true
            });

  const style = useMemo(() => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {})
    }), [
      isDragActive
    ]);


//className="container text-center mt-5"
//<div  className="container text-center mt-5">
  return (
    <div>
      <div {...getRootProps({style})}>
        <input {...getInputProps()} /*disabled={!contextUser.signedIn}*/ />
        <img
              alt="Upload audio file(s)"
              className="img-fluid"
              /*src="file_audio_music_sound_upload-512.png"*/
              /*src="mic_upload6.png"*/
              src="upload_2.png"
            />
            {/*{isDragActive ? 'Please drop file(s)' : 'Click here or drag & drop file(s) to upload!'}*/}

      </div>
      
      <ul className="list-group mt-2">
        {acceptedFiles.length > 0 && acceptedFiles.map(acceptedFile => (
          <li className="list-group-item list-group-item-success" key={acceptedFile.name}>
            {acceptedFile.name} 
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FileUpload;
//</div>
