import React from 'react';
import { Media } from 'react-bootstrap';
import MarvinNavbar from './MarvinNavbar_hook.jsx';

export default function Signup() {
  return (
    <React.Fragment>
     
      <div className='mt-1'></div>
       <Media>
        <img
          width={64}
          height={64}
          className="mr-3"
          src="/la-logo-brand-circle.png"
          alt="Autoscribe"
        />
        <Media.Body>
          <h2>AutoScribe Signup</h2>
          
          <p>
            AutoScribe is currently in closed beta and it's use is by invitation only. 
             </p>
          <p>
            Please send an email to hi@autoscribe.ie to request an account. Thank You.
          </p>
          
          
        </Media.Body>
      </Media>
      
    </React.Fragment>
  );
}
