import React from 'react';
import { Container, Row, Image } from 'react-bootstrap';

class NotFoundPage extends React.Component{
    render(){
        // return <div>
        //     <img src="/404-error.jpg"  />
        //     <p style={{textAlign:"center"}}>
        //       <Link to="/">Go to Home </Link>
        //     </p>
        //   </div>;
        return (
          <Container>
            <Row>
              <Image src="/404-error.jpg" fluid />
            </Row>
          </Container>
        );
    }
}
export default NotFoundPage;
