//Brian Leahy - 19/06/2020 from tutorial
import React, { useCallback, useContext } from "react";
import { withRouter, Redirect} from "react-router";
import { AuthContext } from "./auth.js";
import app from "./firebase.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./signin.css";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
//import m1logoWhite from './HEADMESHWHITE.png';


//<img src={m1logo}  className="App-logo2" alt="logo" />
const Login = ({ history }) => {
    const handleLogin = useCallback(
      async event => {
        event.preventDefault();
        const { email, password } = event.target.elements;
        try {
          await app
            .auth()
            .signInWithEmailAndPassword(email.value, password.value);
          history.push("/");
        } catch (error) {
          alert(error);
        }
      },
      [history]
    );
  
    const { currentUser } = useContext(AuthContext);
    //const { currentUser } = 'brian';
    console.log("Inside login.js, current user is:" + currentUser);

    if (currentUser) {
      return <Redirect to="/files" />;
    }
  
    return (
      <div className="App">
      <header className="App-header">
      <img src="headandmesh.png" className="App-logo2" alt="logo" />
        <Form onSubmit={handleLogin}>
          <Form.Group>
            <Form.Label>
              Welcome to Autoscribe 
             <p> Please sign in</p>
            </Form.Label>
            <Form.Control required autofocus name="email" type="email" placeholder="Enter your email address"/>
            <Form.Control required name="password" type="password" placeholder="Enter your Password"/>
          </Form.Group>
          <Button style={{bgcolor: "#f7931e"}} type="submit">Sign in</Button>
          <h6><p class="mt-5 mb-3 text-muted">&copy; 2020</p></h6>
          <h6><p class="mt-5 mb-3 text-muted"><sup>(V0.9&beta;)</sup></p></h6>
          <h6><p class="mt-5 mb-3 text-muted">If you would like an invite, please email</p><p> hi@autoscribe.ie</p></h6>
        </Form>
        </header>
    </div>
    );
  };
  
  export default withRouter(Login);