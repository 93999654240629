import * as firebase from "firebase/app";
import "firebase/auth";

//Iniitalise Firebase

const app = firebase.initializeApp(

 {
   /* apiKey: process.env.FIREBASE_KEY,
    authDomain:process.env.FIREBASE_DOMAIN ,
    databaseURL:process.env.FIREBASE_DATABASE,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_SENDER_ID*/

    apiKey: "AIzaSyBC6_x2lydZ7xrkahYqJxrEGCFTUNyi3YY",
    authDomain: "marvin-262217.firebaseapp.com",
    databaseURL: "https://marvin-262217.firebaseio.com",
    projectId: "marvin-262217",
    storageBucket: "marvin-262217.appspot.com",
    messagingSenderId: "564735810257",
    appId: "1:564735810257:web:03d86e8180a0ee14ee03f0",
    measurementId: "G-TKQKE6SNRF"
   
 } 
);

export default app;
