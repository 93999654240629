import React from 'react';
import {
  Nav, Modal, Button, NavDropdown, DropdownItem,
} from 'react-bootstrap';

import withToast from './withToast.jsx';
import app from "./firebase.js";
import firebase from "firebase/app";

class SignoutNavItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showing: false,
      disabled: true
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.signOut = this.signOut.bind(this);
    }

  componentDidMount() {
    const clientId = window.ENV.GOOGLE_CLIENT_ID;
    if (!clientId) return;
    window.gapi.load('auth2', () => {
      if (!window.gapi.auth2.getAuthInstance()) {
        window.gapi.auth2.init({ client_id: clientId }).then(() => {
          this.setState({ disabled: false });
        });
      }
    });
    // await this.loadData();
  }

  
  async signOut() {
    console.log("SignoutNavItem, signOut() called");
    //app.auth().signOut();
    firebase.auth().signOut();
  }

  showModal() {
    const clientId = window.ENV.GOOGLE_CLIENT_ID;
    const { showError } = this.props;
    if (!clientId) {
      showError('Missing environment variable GOOGLE_CLIENT_ID');
      return;
    }
    this.setState({ showing: true });
  }

  hideModal() {
    this.setState({ showing: false });
  }

  render() {
    const { user } = this.props;
   /* if (user.signedIn) {
      return (
        <NavDropdown title={user.givenName} id="user">
          <DropdownItem onClick={this.signOut}>Sign out</DropdownItem>
        </NavDropdown>
      );
    }*/
/*show={showing} disabled={disabled}*/
    /*const { showing, disabled } = this.state;*/
    return (
      <>
        <Nav.Link onClick={this.signOut}>
          Sign out
        </Nav.Link>
        {/*<Modal keyboard  onHide={this.hideModal} size="sm">
          <Modal.Header closeButton>
            <Modal.Title>Sign Out</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Button
              block
              
              variant="primary"
              onClick={this.signOut}
            >
              <img src="https://goo.gl/4yjp6B" alt="Sign Out" />
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={this.hideModal}>Cancel</Button>
          </Modal.Footer>
    </Modal>*/}
      </>
    );
  }
}
export default withToast(SignoutNavItem);
