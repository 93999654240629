import React, { Context } from 'react';
//import { NavLink } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Switch, Route, Redirect } from 'react-router-dom';

import BbcEditor from './BbcEditor.jsx';
// import EditorContainer from './EditorContainer.jsx';
import About from './About.jsx';
import Faq from './FAQ.jsx';
import Help from './help.jsx';
import Signup from './signup.jsx';
import Privacy from './privacy.jsx';
import Contents from './Contents.jsx';
import FileUpload from './FileUpload.jsx';
//import MarvinNavbar from './MarvinNavbar_hook.jsx'; <MarvinNavbar user={user} onUserChange={this.onUserChange} />
import MarvinProvider from './MarvinProvider.js';
import MarvinContext from './MarvinContext.js';
import NotFoundPage from './NotFoundPage.jsx';
import UserContext from './UserContext.js';
import { AuthProvider } from "./auth.js"; //BL - 21/10/2020 -Firebase Authentication
import PrivateRoute from "./PrivateRoute";
import Login from "./login.js";
import "./App.css";
const NotFound = () => <h1>Page Not Found</h1>;

export default class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = { user: { signedIn: false } };

    this.onUserChange = this.onUserChange.bind(this);
  }

  async componentDidMount() {
    const apiEndpoint = window.ENV.UI_AUTH_ENDPOINT;
    const response = await fetch(`${apiEndpoint}/user`, {
      method: 'POST',
      credentials: 'include',
    });
    const body = await response.text();
    const result = JSON.parse(body);
// result:
// email: "adrian.matthews@gmail.com"
// givenName: "Adrian"
// iat: 1579691214
// name: "Adrian Matthews"
// signedIn: true
    const { signedIn, givenName } = result;
    this.setState({ user: { signedIn, givenName } });
  }

  onUserChange(user) {
    console.log('onUserChange, signedIn: ' + user.signedIn);
    this.setState({ user });
  }
// <Route path="/bbc" component={EditorContainer} />
  render() {
    const { user } = this.state;
    if (user == null) return null;
    return (
      <div className="Page">
        <AuthProvider>
        
        <MarvinProvider>
          <UserContext.Provider value={user}>
            <Switch>
              <Redirect exact from="/" to="/login" />
              <Route exact path="/login" component={Login} />
              <Route exact path="/privacy" component={Privacy} />
	      <Route exact path="/signup.html" component={Signup} />
	      <Route exact path="/app_help.html" component={Help} />
              <PrivateRoute path="/bbc" component={BbcEditor} />
              <PrivateRoute path="/faq" component={Faq} />
              <PrivateRoute path="/about" component={About} />
              <PrivateRoute path="/files" component={Contents} />
              <PrivateRoute component={NotFoundPage} />
            </Switch>
          </UserContext.Provider>
        </MarvinProvider>
        </AuthProvider>
      </div>
    );
  }
}
