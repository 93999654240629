//Script to acquire Firebase token.
//Used by GCSAUDIOFILELIST.JSX, FILEUPLOAD.JSX
//BRIAN LEAHY 03/11/2020
import firebase from "firebase/app";

async function myFBtoken2()
{
 var mytoken = firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
   // Send token to your backend via HTTPS
   // ...
   if (idToken) { 
     console.log("#1 inside myFBtoken2: Have the Autoscribe token!" + idToken);
     return idToken;
   }
 }).catch(function(error) {
   // Handle error
   console.log("I do not have the autoscribe token :(");
 });
 return mytoken;
}

export default myFBtoken2
