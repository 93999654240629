import React from 'react';
import { Container, Row, Col, Media,Button } from 'react-bootstrap';
import FileUpload from './FileUpload.jsx';
import { Link } from 'react-router-dom';
import MarvinNavbar from './MarvinNavbar_hook.jsx';

export default function About() {
  return (
    <React.Fragment>
     <MarvinNavbar/>
      <div className='mt-5'></div>
       <Media>
        <img
          width={64}
          height={64}
          className="mr-3"
          src="/la-logo-brand-circle.png"
          alt="Autoscribe"
        />
        <Media.Body>
          <h5>What is AutoScribe?</h5>
          <p>
            AutoScribe transcribes audio such as dictations and speech to text files. It
            features an editor for the text files that highlights the
            words alongside playback of the audio.
          </p>
          <p>Autoscribe is currently in Beta testing and available by invite only. Absolutely no warranty
            or gaurantee is given with respect to any loss of files, audio, or financial loss. Your files are stored securely on "the cloud"
            and only you will have access. Do not share your login details unless you want others to access your files.
          </p>
          <p>
            Shared / Linked accounts will be coming in the next version
          </p>
          <p>
            <h2>Some Notes</h2>
            If you make changes to the transcript, currently, you must export the file in order have a copy of the changes. If you leave the transcript and come back, it will revert to the orginal
          </p>
        </Media.Body>
      </Media>
      <div className='App'><Button as={Link} to="/">Back to Your Recordings</Button></div>
    </React.Fragment>
  );
}
