//08/07/2021 - Updated for HTTPS

//import React, { Context } from 'react';
import React from 'react';
//import { Alert, Container, Row, Col, ProgressBar, Spinner } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import GcsAudioFileList from './GcsAudioFileList.jsx';
//import TranscribedFileList from './TranscribedFileList.jsx';

import socketIOClient from "socket.io-client";
import "./App.css";
//const ip_port = process.env.MARVIN_IP + ':' + process.env.API_SERVER_PORT;
//const ip_port = '34.77.190.188' + ':' + '3000'; //hardcoded until I can figure out how to get .env into jsx files
const ip_port = 'www.autoscribe.eu' + ':' + '3000';
export default class TableContainer extends React.Component {
  

  constructor(props) {
    super(props);
    this.state = {
                  now: -1,
                  //endpoint: "http://localhost:3000"
                  endpoint: "https://" + ip_port //HTTPS Change 08/07/21 BL
     };
  }

  componentDidMount() {
    // console.log(" -> load TableContainer");
    console.log("Inside Table Container CDM(): " +JSON.stringify(this.props));

    const {endpoint} = this.state;
    // Connect to the socket
    const socket = socketIOClient(endpoint);
    // Listen for data on the "outgoing data" namespace and supply a callback
    // for what to do when we get one. In this case, we set a state variable
    socket.on("outgoing data", data => this.setState({now: data.num}));
  }

  // API only returns 0 so ProgresBar is useless. Show Spinner instead.
  // <ProgressBar variant="info" now={this.state.now} label={`${this.state.now}%`} />

  render() {
    return (
      <div>
        {(this.state.now > -1 && this.state.now < 100) &&
          <div className='mt-5'>
            <h2>Transcribing. Please Wait.</h2>
            <h5>Transcribe time is usually less than half the duration of the file. </h5>      
            <Spinner animation="border text-warning" role="progress">
              <span className="sr-only">Transcribing...</span>
            </Spinner>
          </div>
        }
        <Row>
          <GcsAudioFileList />
        </Row>
        {/*<Row>
          <TranscribedFileList />
        </Row>*/}
      </div>
    );
  }
}
