//27/09/2021 - Updated for Sharing - see code marked 19/08/21 for changes
/*****THIS IS THE ONE TO UPDATE*/
import React, { useContext } from 'react';
import { Button, Table } from 'react-bootstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';
import UserContext from './UserContext.js';

// Assume we need a quick way to delete an audioFile from the AudioFile List
// page itself. To achieve this, a button is needed in every row to initiate
// the operation. This button is is part of the Actions column. On click of this
// button, a delete action will be initiated , which can be a function passed in
// as a callback in the props. The callback needs to be passed from
// AudioFileList via AudioFileTable to AudioFileRow. Further, to identify which
// audioFile to delete, we’ll also have to receive the index of the audioFile in
// the table as another value in the props. The index can be computed in the
// AudioFileTable component itself, while iterating over the list of audioFiles.


/*Modified by Brian Leahy 19/09/2019 - Removed "" as all data now comes from Firebase (hopefully)
*/

const AudioFileRow = withRouter(({ audioFile,
  location: { search },
  deleteAudioFile,
  transcribeAudioFile,
  handleSpeakerChange,
  editTranscribedFile, //BL 28/09/20
  index,
}) => {
  const contextUser = useContext(UserContext);
  
  /*Enable or disable the transribe button*/
  var my_switch = (audioFile.transcriptName === "") ? false:true;
  
  console.log("Display name is: " + audioFile.displayName);
  var filename_no_ext = audioFile.displayName;
  //filename_no_ext = "arse";
  var my_dot = filename_no_ext.lastIndexOf('.');
//<td>{audioFile.displayName}</td>
  //var my_sub = filename_no_ext.slice(0,my_dot);
  var my_sub = filename_no_ext;
//BL: server.js /list -->audioFile.mongoId (might need to change this to a UUID or something -- remeber the ID issues!?)

//convert duration into hrs,mins,secs
var strHRS,strMIN,strSEC,rndDuration;
var myColour ="green";

rndDuration = Math.ceil(audioFile.audioDuration);

var hours = Math.floor(rndDuration / 60 / 60);
var minutes = Math.floor(rndDuration / 60) - (hours * 60);
var seconds = rndDuration % 60;
if (hours < 10){strHRS = "0" + hours} else {strHRS = hours};
if (minutes < 10){strMIN = "0" + minutes} else (strMIN = minutes);
if (seconds < 10){strSEC = "0" + seconds} else {strSEC = seconds};
var Duration_format = strHRS + ':' + strMIN + ':' + strSEC;

//If the file is greater than 18mins long, has come from the app and hasn't been transcribed yet, set to true - it will be used to format the name and duration to red text
if(audioFile.audioDuration >1080 && audioFile.uploadSource !=="Web" & audioFile.transcriptName === "")
{
  myColour ="#eb7506"; 
}
else
{
  myColour = "black";
}

console.log("#6128. converted audio is:" + audioFile.convertedAudioFilename)
var m = "blue";

    return (
      <tr>
      
      <td style={{ color: myColour }}>{my_sub}</td>
      <td style={{ color: myColour }}>{Duration_format}</td>
      <td>
        <select defaultValue={audioFile.numberOfSpeakers} mongo-id={audioFile.mongoId} onChange={() => {handleSpeakerChange(event)}}>
          <option value="1">One</option>
          <option value='2'>Two</option>
          <option value='3'>Three</option>
          <option value='4'>Four</option>
          <option value='5'>Five</option>
        </select>
      </td>
      {/*<td>{audioFile.audioLanguage}</td>*/}
      <td>
        <select defaultValue={audioFile.audioLanguage} mongo-id={audioFile.mongoId} onChange={() => {handleLanguageChange(event)}}>
          <option value="en-IE">English-Ire</option>
          <option value='en-UK'>English-UK</option>
          <option value='fr-FR'>French</option>
          <option value='de-DE'>German</option>
          <option value='es-ES'>Spanish</option>
          <option value='it-IT'>Italian</option>
          <option value='pl-PL'>Polish</option>
        </select>
      </td>
      <td> 
        <Button data-key={audioFile.mongoId} variant="warning"
                //disabled={!contextUser.signedIn}
                disabled={my_switch} //if transcriptName is not blank this means we have already transcribed so we don't allow another roll at it
                onClick={() => {
                  //transcribeAudioFile(audioFile.filename, audioFile.codec, audioFile.sampleRate, audioFile.numberOfChannels, audioFile.mongoId, audioFile.audioLanguage);
                  transcribeAudioFile(audioFile.convertedAudioFilename, audioFile.codec, audioFile.sampleRate, audioFile.numberOfChannels, audioFile.mongoId, audioFile.audioLanguage, audioFile.filename);
                }}>Transcribe</Button> {/*was audiofile.name*/}
        {' | '} {/*was info*/}
        <Button variant="success" 
                disabled={!my_switch} //if transcriptName is blank then we can't edit
               onClick={() => {
                //editTranscribedFile(transcribedFile.name, transcribedFile.metadata.mongoId, transcribedFile.metadata.audioName);
                editTranscribedFile(audioFile.transcriptName, audioFile.id, audioFile.convertedAudioFilename,audioFile.originalname, audioFile.filename); //19/08/21 added name - for sharing
              }}>Edit</Button>
        {' | '}
        <Button variant="danger"
                //disabled={!contextUser.signedIn}
                onClick={() => {
                  deleteAudioFile(audioFile.filename, audioFile.displayName, audioFile.convertedAudioFilename,audioFile.codec);
                }}>Delete</Button> {/*was audiofile.name*/}
      </td>
    </tr>
  );
});

export default function AudioFileTable ({ audioFiles, deleteAudioFile, transcribeAudioFile, handleSpeakerChange,handleLanguageChange, editTranscribedFile }) {
  const audioFileRows = audioFiles.map((audioFile, index) => (
    <AudioFileRow
      key={audioFile.name} //was name
      audioFile={audioFile}
      deleteAudioFile={deleteAudioFile}
      transcribeAudioFile={transcribeAudioFile}
      handleSpeakerChange={handleSpeakerChange}
      handleLanguageChange={handleLanguageChange}
      editTranscribedFile={editTranscribedFile} //BL 28/09/20
      index={index} />
  ));

  return (
    <div className="App">
      <h1>Your Recordings</h1>
      <Table w-auto striped bordered hover>
        <thead>
          <tr>
            <th  bgcolor="1B1464"><font color="ffffff">File Name</font></th>
            <th bgcolor="1B1464"><font color="ffffff">Duration</font></th>
            <th bgcolor="1B1464"><font color="#f7931e">Number of <p>Speakers</p></font></th>
            <th bgcolor="1B1464"><font color="ffffff">Language</font></th>
            <th width="300" bgcolor="1B1464"><font color="ffffff">Actions</font></th>
          </tr>
        </thead>
        <tbody>
          {audioFileRows}
        </tbody>
      </Table>
    </div>
  );
}
