import React, { Context } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import FileUpload from './FileUpload.jsx';
import TableContainer from './TableContainer.jsx';
/*import TableContainer2 from './TableContainer_test.jsx';
import Mytable from './mytable.jsx'
import Mytable2 from './mytable.jsx'*/
//Test to see if I can only have navbar
import MarvinNavbar from './MarvinNavbar_hook.jsx';

import GcsAudioFileList from './GcsAudioFileList.jsx';

import "./App.css";
//md={4} or 8
const NotFound = () => <h1>Page Not Found</h1>;
//<MarvinNavbar/>
/*<Col md={4} >
<FileUpload/>
</Col>
<TableContainer/>*/

export default class Contents extends React.Component {
  render (){
    return (
      <Container>
     <MarvinNavbar/>
      <Row >
      <Col md={4}> <FileUpload/>  </Col>
      <Col md={8}><TableContainer/></Col>
      </Row>
      </Container>
       

     
    );
  }
}

/*<Container>
     <MarvinNavbar/>
      <Row flex-nowrap>
      <Col> <FileUpload/>  </Col>
      <Col><GcsAudioFileList /> </Col>
      </Row>
      </Container>*/
      