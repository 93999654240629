import React from 'react';
import { Container, Row, Col, Media,Button } from 'react-bootstrap';
import FileUpload from './FileUpload.jsx';
import { Link } from 'react-router-dom';
import MarvinNavbar from './MarvinNavbar_hook.jsx';

export default function Faq() {
  return (
    <React.Fragment>
     <MarvinNavbar/>
      <div className='mt-5'></div>
       <Media>
        <img
          width={64}
          height={64}
          className="mr-3"
          src="/la-logo-brand-circle.png"
          alt="Autoscribe"
        />
        <Media.Body>
          <h5>Known issues & quirks of the prototype</h5>
          <p>
            If you have uploaded audio via the app and both the edit and transcribe buttons are disabled, this means that the file is still being transcribed.
            You can refresh the page by clicking on the logo in the bar at the top. Please note that it can take up to half the duration of the audio to have the transcription ready.
          </p>
          <p>
            If your recording is more than 18 minutes long and it was uploaded via the app, it will be highlighted and you must manuallt press the "Transcribe" button
            This will be the case, whether or not you requested the audio to be automatically transcribed. This will be rectified in the full release
          </p>
          <p>
            If you have any issues, try refreshing the page by clicking on the logo in the top left corner and then try again.
          </p>
          <p>
            <h2>The Editor</h2>
            If you make changes to the transcript, currently, you must export the file in order have a copy of the changes. If you leave the transcript and come back, it will revert to the orginal
            Try using the shortcut keys to make editing faster. For example [ALT+R] to rewind. You can adjust the times in the settings of the editor
          </p>
        </Media.Body>
      </Media>
      <div className='App'><Button as={Link} to="/">Back to Your Recordings</Button></div>
    </React.Fragment>
  );
}
