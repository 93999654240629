import React from 'react';
import { useLocation } from 'react-router-dom';

import TranscriptEditor from '@bbc/react-transcript-editor';
import SttTypeSelect from './bbc/select-stt-json-type';
import ExportFormatSelect from './bbc/select-export-format';

//Test to see if I can only have navbar
import MarvinNavbar from './MarvinNavbar_hook.jsx';

// Note: modules not applied in webpack.config so use styles directly instead of styles.container
import styles from './bbc/index.module.scss';

import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
/* These options are taken out for the moment - to make it look noiceer*/
/* <section className='demoNavItem'>
            <label className='sectionLabel'><b><u>Options</u></b></label>

            <div className='checkbox'>
              <label className='editableLabel' htmlFor={ 'textIsEditableCheckbox' }>Text Is Editable</label>
              <input
                id={ 'textIsEditableCheckbox' }
                type="checkbox"
                checked={ this.state.isTextEditable }
                onChange={ this.handleIsTextEditable }
              />
            </div>

            <div className='checkbox'>
              <label className='editableLabel' htmlFor={ 'spellCheckCheckbox' }>Spell Check</label>
              <input
                id={ 'spellCheckCheckbox' }
                type="checkbox"
                checked={ this.state.spellCheck }
                onChange={ this.handleSpellCheck }
              />
            </div>
          </section>
  */
class BbcEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      transcriptData: this.props.location.state.fileContents,
      mediaUrl: this.props.location.state.mediaUrl,
      isTextEditable: true,
      //spellCheck: false,
      spellCheck: true,
      sttType: 'google-stt',
      fileName: this.props.location.state.orginalname,
      title: this.props.location.state.orginalname //BL 30/09/2020
    };

    this.transcriptEditorRef = React.createRef();

     //console.log('File ID: ' + this.props.location.state.id);
     //console.log('File contents: ' + this.props.location.state.fileContents);
     //console.log('File Name1: ' + this.state.fileName);
  }

  handleIsTextEditable = (e) => {
    this.setState({
      isTextEditable: e.target.checked
    });
  };

  handleSpellCheck = (e) => {
    this.setState({
      spellCheck: e.target.checked
    });
  };

  handleExportFormatChange = event => {
    console.log(event.target.name, event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };

  exportTranscript = () => {
    console.log('exportT' + this.state.fileName);
    // eslint-disable-next-line react/no-string-refs
   const { data, ext } = this.transcriptEditorRef.current.getEditorContent(
      this.state.exportFormat || 'docx' /*Added "or" option so that default export format is MS Word*/
      
    );

    this
    let tmpData = data;
   if (ext === 'json') {
   tmpData = JSON.stringify(data, null, 2);
    }
   if (ext !== 'docx') {
   this.download(tmpData, `${ this.state.fileName }.${ ext }`);
   }
  };

  // https://stackoverflow.com/questions/2897619/using-html5-javascript-to-generate-and-save-a-file
  download = (content, filename, contentType) => {
    console.log('download');
    const type = contentType || 'application/octet-stream';
    const link = document.createElement('a');
    const blob = new Blob([ content ], { type: type });

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    // Firefox fix - cannot do link.click() if it's not attached to DOM in firefox
    // https://stackoverflow.com/questions/32225904/programmatical-click-on-a-tag-not-working-in-firefox
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  handleChangeTranscriptName = value => {
    this.setState({ fileName: value });
  };

  render() {
    return (
      
     <div className='container Editor'>
        <MarvinNavbar/>
        <span className='App'><h2>Your Transcript Editor</h2></span>
        <div className='demoNav' style={{ display: "flex", justifyContent: "center" }}>

          <section className='demoNavItem'>
            <label style={{ display: "flex", justifyContent: "center" }} className='sectionLabel'>Choose your Export Format</label>
            <p></p>
            <ExportFormatSelect
              className='dropdown'
              name={ 'exportFormat' }
              value={ this.state.exportFormat }
              /*value={ 'docx' }*/
              handleChange={ this.handleExportFormatChange }
            />
            <Button onClick={ () => this.exportTranscript() } variant ="primary" >Export File</Button>
            
            <div  style={{ display: "flex", justifyContent: "center" }}>
            <Button variant ="success" as={Link} to="/">Back to Your Recordings</Button>
            </div>
          </section>

         
        </div>

        <TranscriptEditor
          transcriptData={ this.state.transcriptData }
          fileName={ this.state.fileName }
          mediaUrl={ this.state.mediaUrl }
          isEditable={ this.state.isTextEditable }
          spellCheck={ this.state.spellCheck }
          sttJsonType={ this.state.sttType }
          ref={ this.transcriptEditorRef }
          title={this.state.title}
        />
        
      </div>
    );
  }
}
export default BbcEditor;
