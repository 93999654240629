import React, { useCallback, useContext } from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import { AuthContext } from "./auth.js";
//import SignInNavItem from './SignInNavItem.jsx';
import SignOutNavItem from './SignOutNavItem.jsx';
import app from "./firebase.js";
import "./App.css";
const MarvinNavbar = () => {
    
    
    //const { currentUser } = 'brian';
    //console.log("Inside Navbar.js, current user is:" + JSON.stringify(this.arse));
    //fixed="top"
    var { currentUser } = useContext(AuthContext);
    var username;

    if (currentUser)
    {
        username = currentUser.email;
    }
    else{
        username = "please log in!";
    }
    return (
      <Navbar fixed="top" className='mb-5' collapseOnSelect expand="lg"  variant="dark">
        
        <Navbar.Brand as={Link} to="/" ><img
          alt=""
          src="/HEADMESHWHITE.png"
          width="30"
          height="30"
          className="d-inline-block align-top"
        />
        {' Welcome to Autoscribe. You are signed in as: '}
        {<font color="#f7931e"><b>{username}</b></font>}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <SignOutNavItem />
            <Nav.Link as={Link} to="/faq">FAQ</Nav.Link>
            <Nav.Link as={Link} to="/about">About</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
        )
    }


export default MarvinNavbar;
